import React from "react";
import miningImg from "../../assets/images/mining/Mining(12).JPG";
import { Link } from "react-router-dom";
const Mining = () => {
  return (
    <div className="container d-flex justify-content-center pb-2">
      <div className="w-100 row mt-5 pt-2">
        <div className="col-md-6 col-sm-12 d-flex justify-content-center">
          <img
            className="w-100 rounded shadow"
            style={{ width: "100px" }}
            src={miningImg}
            alt="jcb"
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-3">
          <h2 className="text-center heading-color">Mining</h2>
          <p className="p-3">
            We specialize in mining, procuring, and delivering high-quality raw
            and crushed minerals to meet diverse industry needs. Our efficient
            processes ensure that we provide reliable and timely supply while
            maintaining the highest standards of safety and environmental
            responsibility. Whether it's for construction, manufacturing, or
            other applications, we deliver the materials that drive progress and
            performance
          </p>
          <Link
            className="btn button-info col-md-3 col-sm-12 mt-3 text-white rounded-pill d-block mx-auto"
            to="/services/mining"
          >
            See More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Mining;
