import React from "react";
import Card from "react-bootstrap/Card";

const AboutSection = () => {
  const cardsData = [
    {
      title: "Vision",
      desc: "To lead in constructing accessible roads, affordable housing, and mining resources, transforming communities, enhancing lives, and driving progress for all.",
    },
    {
      title: "Mission",
      desc: "To deliver affordable roads, housing, and essential minerals, improving infrastructure, fostering growth, and creating employment opportunities that uplift communities and support sustainable development.",
    },
    {
      title: "Values",
      desc: "We value quality, affordability, sustainability, community, innovation, integrity, and creating employment opportunities in every project we undertake.",
    },
  ];

  return (
    <div className="about-section w-100 row mt-3 pt-2 justify-content-center">
      <h2 className="text-center heading-color">About Us</h2>
      <p className="intro-text p-3 text-center">
        At Dheemantha Infra, we shape the future by mastering the art of
        constructing roads, buildings, and infrastructure. Our expertise extends
        to mining valuable minerals that fuel innovation and development. We’re
        committed to delivering quality and sustainability in every project,
        laying strong foundations and paving the way for lasting growth that
        benefits both communities and industries.
      </p>
      <div className="card-container row d-flex justify-content-center">
        {cardsData.map((cardItem, index) => {
          return (
            <Card
              key={index}
              className="about-card col-lg-3 col-md-4 col-sm-8 col-10 text-center mb-4 px-0 hover-effect"
            >
              <Card.Header className="card-header text-center button-info">
                <b className="text-white">{cardItem.title}</b>
              </Card.Header>
              <Card.Body className="card-body">
                <Card.Text className="card-text">{cardItem.desc}</Card.Text>
              </Card.Body>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default AboutSection;
