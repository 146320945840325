import React from "react";
import { Card } from "react-bootstrap";
import "./ProjectList.css";

const ProjectList = ({ projectList, title }) => {
  return (
    <div className="project-list-container">
      <h1 className="mt-5 text-center heading-color">{title} Projects</h1>
      <br />
      <div className="row justify-content-center">
        {projectList.map((project, index) => (
          <Card key={index} className="project-card mb-4 px-0 hover-effect">
            <Card.Img
              variant="top"
              src={project.imgUrl}
              className="project-image"
            />
            <Card.Body>
              <Card.Title>
                <b className="heading-color">{project.name}</b>
              </Card.Title>
              <Card.Text className="text-color">
                {project.description}
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ProjectList;
