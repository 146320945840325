import React from "react";
import minerals from "../../assets/images/mining/Mining(13).JPG";

const Mining = () => {
  return (
    <div className="container p-5 loading-animation" style={{ minHeight: "80vh" }}>
      <div className="row align-items-center">
        <div className="col-md-6 col-sm-12 mb-4 mb-md-0 d-flex flex-column justify-content-center text-md-start text-center">
          <h1 className="text-center text-md-start heading-color mb-4">Mining</h1>
          <p className="p-3">
            Our mining services specialize in the extraction and processing of high-quality minerals. We excel in the excavation of quartz and other essential minerals, ensuring precision and efficiency. Our expertise covers the entire process—from extraction to processing—where we sort and refine minerals into various sizes to meet specific industry needs. With a commitment to safety, sustainability, and innovation, we deliver reliable and timely mineral resources that drive progress and support diverse applications.
          </p>
        </div>
        <div className="col-md-6 col-sm-12 d-flex justify-content-center">
          <img
            className="img-fluid rounded shadow"
            src={minerals}
            alt="Mining"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Mining;
