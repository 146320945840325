import React, { useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImg from "../../assets/images/placeholder-img.png";
import "react-lazy-load-image-component/src/effects/blur.css";
import { motion, useInView } from "framer-motion";

const GalleryList = ({ list, category }) => {
  const ref = useRef();
  const isInView = useInView(ref, { once: true });
  const cardVariants = {
    initial: { y: 50, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };
  return (
    <div ref={ref}>
      <Row className="mt-2 mb-3">
        {list.map((image, i) => {
          return (
            <Col xs={12} md={4} key={i}>
              <motion.div
                key={i}
                variants={cardVariants}
                initial="initial"
                animate={isInView ? "animate" : "initial"}
                transition={{ duration: 0.3, delay: i * 0.4 }}
              >
                <Card className="mb-3 px-0 hover-effect">
                  <LazyLoadImage
                    alt={category}
                    // placeholder={<div>Wait......</div>}
                    placeholderSrc={PlaceholderImg}
                    effect="blur"
                    src={image.imgUrl}
                    height={250}
                    width={"100%"}
                  />
                </Card>
              </motion.div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default GalleryList;
