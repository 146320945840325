import React from "react";
import AboutSection from "./aboutSection";
import ContactUs from "./Contact";
import Leadership from "./Leadership";

const VisionAndMission = () => {
  return (
    <div className="p-4 mt-5 mb-5 loading-animation">
      <div className="container">
        <AboutSection />
        <Leadership />
        <ContactUs/>
      </div>
    </div>
  );
};

export default VisionAndMission;
