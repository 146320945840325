import React from "react";

const Footer = () => {
  return (
    <div className="bg-dark text-center text-white w-100 p-3">
      <p>Copyright © 2024 Dheemantha Infra - All Rights Reserved.</p>
    </div>
  );
};

export default Footer;
