import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";
import "./styles.css";
import ms from "../../assets/images/meda_suresh.JPG";

const Leadership = () => {
  const employees = [
    // Board of Directors Members with photoUrl
    {
      id: 1,
      name: "Meda Suresh",
      department: "Board of Directors",
      role: "Managing Director",
      location: "Vijayawada, AndhraPradesh",
      contact: "medasuresh73@gmail.com",
      phone: "8008022299",
      startDate: "16-09-2024",
      description:
        "Oversees the strategic direction of the company and ensures effective governance.",
      photoUrl: ms,
    },
  ];
  return (
    <div className="container">
      <h1 className="mt-4 text-center heading-color">Leadership</h1>
      <Accordion defaultActiveKey="0" className="m-4">
        <Accordion.Item eventKey="bod" className="mb-3">
          <Accordion.Header>
            <h4 className="heading-color">Board of Directors</h4>
          </Accordion.Header>
          <Accordion.Body>
            {employees.map((emp) => (
              <Alert
                variant="light"
                className="d-flex align-items-start"
                key={emp.id}
              >
                <img
                  src={emp.photoUrl}
                  alt={emp.name}
                  className="rounded-circle me-3"
                  style={{ width: "100px", height: "100px" }}
                />
                <div>
                  <h5>{emp.name}</h5>
                  <p>
                    <b>{emp.role}</b>
                    <br />
                    {emp.description}
                  </p>
                  <div className="contact-info">
                    <b>Contact Info</b>
                    <p>
                      <strong>Email:</strong> {emp.contact}
                    </p>
                    <p>
                      <strong>Phone:</strong> {emp.phone}
                    </p>
                    <p>
                      <strong>Address:</strong> {emp.location}
                    </p>
                  </div>
                </div>
              </Alert>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Leadership;
