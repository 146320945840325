import React from "react";
import constructionImg from "../../assets/images/roads/road (6).jpg";

const Construction = () => {
  return (
    <div
      className="container p-5 loading-animation"
      style={{ minHeight: "80vh" }}
    >
      <div className="row align-items-center">
        <div className="col-md-6 col-sm-12 mb-4 mb-md-0 d-flex flex-column justify-content-center text-md-start text-center">
          <h1 className="text-center text-md-start heading-color mb-4">
            Construction
          </h1>
          <p className="p-3">
            We offer comprehensive construction services that include the
            development of roads, buildings, and infrastructure. Our team is
            dedicated to creating high-quality, durable projects that meet the
            needs of our clients and communities. From planning and design to
            execution and completion, we handle every aspect of construction
            with a focus on affordability and excellence. Our commitment to
            sustainable practices and innovative solutions ensures that each
            project not only stands the test of time but also enhances the
            overall quality of life for those it serves.
          </p>
        </div>
        <div className="col-md-6 col-sm-12 d-flex justify-content-center">
          <img
            className="img-fluid rounded shadow"
            src={constructionImg}
            alt="Construction"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Construction;
