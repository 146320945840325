import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import c1 from "../../assets/images/construction.webp";
import c2 from "../../assets/images/mining_c.jpeg";
import c3 from "../../assets/images/roadworks.jpeg";
import { Link } from "react-router-dom";
import './CarouselSection.css';  

const Images = [c1, c2, c3];

const CarouselSection = () => {
  const [showCarousel, setShowCarousel] = useState(false); 

  useEffect(() => {
    const updateLayout = () => {
      const isMobile = window.innerWidth <= 768;
      if (isMobile) {
        setShowCarousel(true);
      } else {
        const timer = setTimeout(() => {
          setShowCarousel(true); 
          const carouselElement = document.getElementById("carousel-section");
          if (carouselElement) {
            window.scrollTo({
              top: carouselElement.offsetTop,
              behavior: "smooth",
            });
          }
        }, 1500);

        return () => clearTimeout(timer);
      }
    };

    updateLayout(); 
    window.addEventListener("resize", updateLayout); 

    return () => window.removeEventListener("resize", updateLayout); 
  }, []);

  return (
    <div>
      {!showCarousel ? (
        <div className="logo"></div> 
      ) : (
        <div id="carousel-section" className="carousel-container">
          <Carousel fade className="carousel"> 
            {Images.map((img, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100 carousel-image"
                  src={img}
                  alt={`carousel-${index}`}
                />
                <Carousel.Caption className="carousel-caption">
                  <h3>Welcome to Dheemantha Infra</h3>
                  <p>Building the future, from Foundations to Minerals.</p>
                  <Link className="carousel-button" to="/about-us">
                    <p>See More</p>
                  </Link>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default CarouselSection;
