import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../assets/images/Dheemantha_logo.jpg";
import { Link } from "react-router-dom";

import "./Header.css";

const Header = () => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-white border-bottom shadow-sm"
      sticky="top"
    >
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img
            alt="Dheemantha Infra Private Limited"
            loading="lazy"
            src={Logo}
            width="50"
            height="50"
            className="d-inline-block align-top"
          />
          <b className="heading-color nav-heading ms-3">
            Dheemantha Infra Pvt. Ltd.
          </b>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto text-center">
            <Nav.Link as={Link} to="/" className="nav-link">
              Home
            </Nav.Link>
            <NavDropdown
              title="Services"
              id="services-nav-dropdown"
              className="text-center"
            >
              <NavDropdown.Item as={Link} to="/services/mining">
                Mining
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/services/construction">
                Construction
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/projects" className="nav-link">
              Projects
            </Nav.Link>
            <Nav.Link as={Link} to="/media" className="nav-link">
              Media
            </Nav.Link>
            <Nav.Link as={Link} to="/about-us" className="nav-link">
              About Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
