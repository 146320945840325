import React from "react";
import DMI from "../../assets/images/Dheemantha_logo.jpg";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <div className="container d-flex justify-content-center pb-2">
      <div className="w-100 row mt-5 pt-2">
        <div className="col-md-6 col-sm-12 d-flex justify-content-center">
          <img
            style={{ width: "80%" }}
            src={DMI}
            alt="jcb"
          />
        </div>
        <div className="col-md-6 col-sm-12 mb-3">
          <h2 className="text-center heading-color">About Dhemanta infra</h2>
          <p className="p-3 text-color">
            At Dheemantha Infra, we shape the future by mastering the art of
            constructing roads, buildings, and infrastructure. Our expertise
            extends to mining valuable minerals that fuel innovation and
            development. We’re committed to delivering quality and
            sustainability in every project, laying strong foundations and
            paving the way for lasting growth that benefits both communities and
            industries.
          </p>
          <Link
            className="btn button-info col-md-3 col-sm-12 mt-3 text-white rounded-pill d-block mx-auto"
            to="/about-us"
          >
            See More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
