import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const ContactUs = () => {
  return (
    <section className="contact-us py-5">
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <h2 className="heading-color">Contact Us</h2>
            <br/>
            <p>For inquiries, quotes, or more information about our services, please reach out to us using the contact details below. </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={5}>
            <div className="contact-details text-center">
              <p><strong>Phone:</strong> 8008022299</p>
              <p><strong>Email:</strong> dheemanthainfra@gmail.com, support@dheemanthainfra.com</p>
            </div>
          </Col>
        </Row>
        {/* Uncomment the map section if needed */}
        {/* <Row className="mt-4">
          <Col>
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=..."
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Company Location"
              ></iframe>
            </div>
          </Col>
        </Row> */}
      </Container>
    </section>
  );
};

export default ContactUs;
