import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/header";
import Home from "./components/home/home";
import Footer from "./components/footer";
import ScrollToTop from "./components/scrollToTop";

//services
import Construction from "./components/services/Construction";
import Mining from "./components/services/Mining";
//media
import Gallery from "./components/media/Gallery";
//aboutus

import VisionAndMission from "./components/about/VisionAndMission";
import "bootstrap/dist/css/bootstrap.min.css";
import Projects from "./components/projects/Projects";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services/mining" element={<Mining />} />
          <Route path="/services/construction" element={<Construction />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/media" element={<Gallery />} />
          <Route path="/about-us" element={<VisionAndMission />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
