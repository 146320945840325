import React from "react";
import jcbImage from "../../assets/images/jcb.jpg";
import { Link } from "react-router-dom";

const RoadsInfrastructure = () => {
  return (
    <div className="container d-flex justify-content-center pb-2 mb-5">
      <div className="w-100 row mt-5 pt-2">
        <div className="col-md-6 col-sm-12 mb-3">
          <h2 className="text-center heading-color">Roads and Infrastructure</h2>
          <p className="p-3">
            We’re dedicated to connecting communities by constructing essential
            roadways and infrastructure. Our focus is on bridging gaps between
            villages and creating affordable living spaces that enhance quality
            of life. By working hard to build reliable, accessible
            infrastructure, we support growth and bring people closer together.
          </p>
          <Link
            className="btn button-info col-md-3 col-sm-12 mt-3 text-white rounded-pill d-block mx-auto"
            to="/services/construction"
          >
            See More
          </Link>
        </div>
        <div className="col-md-6 col-sm-12 d-flex justify-content-center">
          <img
            className="img-fluid rounded shadow"  // Use img-fluid for responsiveness
            src={jcbImage}
            alt="jcb"
          />
        </div>
      </div>
    </div>
  );
};

export default RoadsInfrastructure;
