import React from "react";
import CarouselSection from "./Carousel";
import About from "./About";
import Mining from "./Mining";
import RoadsInfrastructure from "./RoadsInfrastructure";
const Home = () => {
  return (
    <section>
      <CarouselSection />
      <About />
      <RoadsInfrastructure />
      <Mining />
      <br/>
    </section>
  );
};

export default Home;
